@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background-gray: rgba(250, 250, 250, 1);
  --black: #292626;
  --buttons-font-size: 14px;
  --buttons-font-style: normal;
  --buttons-font-weight: 500;
  --buttons-letter-spacing: 0px;
  --buttons-line-height: 30px;
  --buttons-on-hover: rgba(101, 51, 184, 1);
  --collection-1-color: rgba(255, 255, 255, 1);
  --error: rgba(246, 67, 82, 1);
  --success: #31dc7c;
  --success-background: #edf5f1;
  --error-background: #ffeff0;
  --gray-900: rgba(16, 24, 40, 1);
  --h1-mobile-font-size: 28px;
  --h1-mobile-font-style: normal;
  --h1-mobile-font-weight: 700;
  --h1-mobile-letter-spacing: 0px;
  --h1-mobile-line-height: normal;
  --h1-title-font-size: 38px;
  --h1-title-font-style: normal;
  --h1-title-font-weight: 400;
  --h1-title-letter-spacing: 0px;
  --h1-title-line-height: normal;
  --h2-mobile-font-size: 18px;
  --h2-mobile-font-style: normal;
  --h2-mobile-font-weight: 500;
  --h2-mobile-letter-spacing: 0px;
  --h2-mobile-line-height: normal;
  --h2-titles-font-size: 24px;
  --h2-titles-font-style: normal;
  --h2-titles-font-weight: 400;
  --h2-titles-letter-spacing: 0px;
  --h2-titles-line-height: normal;
  --h3-titles-font-size: 18px;
  --h3-titles-font-style: normal;
  --h3-titles-font-weight: 400;
  --h3-titles-letter-spacing: 0px;
  --h3-titles-line-height: 24px;
  --input-text-font-size: 14px;
  --input-text-font-style: normal;
  --input-text-font-weight: 400;
  --input-text-letter-spacing: 0px;
  --input-text-line-height: 30px;
  --input-titles-font-size: 12px;
  --input-titles-font-style: normal;
  --input-titles-font-weight: 400;
  --input-titles-letter-spacing: 0px;
  --input-titles-line-height: 14px;
  --light-purple: rgba(248, 242, 252, 1);
  --lines: #e2e3e6;
  --paragraph-font-size: 16px;
  --paragraph-font-style: normal;
  --paragraph-font-weight: 400;
  --paragraph-letter-spacing: 0px;
  --paragraph-line-height: 24px;
  --primary: rgba(137, 90, 215, 1);
  --primary-hover: #6533b8;
  --schools-cards-font-size: 14px;
  --schools-cards-font-style: normal;
  --schools-cards-font-weight: 500;
  --schools-cards-letter-spacing: 0px;
  --schools-cards-line-height: 18px;
  --secondary: #2f155f;
  --small-paragraph-font-size: 12px;
  --small-paragraph-font-style: normal;
  --small-paragraph-font-weight: 400;
  --small-paragraph-letter-spacing: 0px;
  --small-paragraph-line-height: 18px;
  --text: #5e636f;
  --violet: rgba(230, 222, 255, 1);
  --white: rgba(255, 255, 255, 1);
  --sm-border-radius: 4px;
  --md-border-radius: 8px;
  --border-radius: 20px;
  --default-space: 8px;

  --paragraph-font-size: 16px;
  --paragraph-font-style: normal;
  --paragraph-font-weight: 400;
  --paragraph-line-height: 24px;

  --h3-font-size: 18px;
  --h3-font-style: normal;
  --h3-font-weight: 600;
  --h3-line-height: 24px;

  --h1-title-font-size: 38px;
  --h1-title-font-style: normal;
  --h1-title-font-weight: 400;
  --h1-title-line-height: normal;

  --h2-font-size: 24px;
  --h2-font-style: normal;
  --h2-font-weight: 500;
  --h2-line-height: normal;
}

html,
body,
#__next {
  @apply h-full;
  @apply scroll-smooth;
}

body {
  background-color: #fff;
}

hr.filter-divider {
  color: var(--lines);
}

span.input-error-message {
  color: var(--error, #f64352);
  font-size: var(--input-titles-font-size);
}

input[type='checkbox'] {
  position: relative;
  cursor: pointer;
  background-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-title;
}

label {
  @apply font-medium;
}

input {
  @apply font-sans;
}

input:focus,
select:focus {
  border-color: #895ad7;
}

.hr-text {
  border: 0;
  height: 1.5em;
  line-height: 1em;
  position: relative;
  text-align: center;
  @apply text-xs;
}

.hr-text::before {
  content: '';
  background: var(--lines);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text::after {
  font-weight: 400;
  background-color: #fff;
  color: black;
  content: attr(data-content);
  line-height: 1.5em;
  padding: 0 2rem;
  position: relative;
}

[class*='FloatingButton__FloatingButtonContainer'] {
  z-index: 5 !important;
}

@keyframes pulse-custom {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.6;
  }
  100% {
    transform: scale(0.8);
    opacity: 1;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .full-bleed {
    @apply relative right-1/2 left-1/2 w-screen;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  /** counter reacts full-bleed */
  .contained {
    width: 100%;
    left: unset;
    right: unset;
    margin-left: unset;
    margin-right: unset;
  }

  .animate-pulse-custom {
    animation: pulse-custom 1.5s infinite ease-in-out;
  }

  .markdown-content {
    ul,
    ol {
      @apply py-4 ps-10;
    }

    ol {
      @apply list-decimal;
    }

    h1 {
      @apply py-5 md:text-h1 text-h1-mobile md:leading-h1 leading-h1-mobile;
    }

    h2 {
      @apply md:text-h2 text-h2-mobile md:leading-h2 leading-[120%] tracking-[-0.6px] !text-3xl my-2;
    }

    p {
      @apply md:text-body-p text-body-p-mobile;
    }
  }
}
